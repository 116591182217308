<template>
    <div class="row my-4">
        <div class="col-12 col-lg-4">
            <div class="card">
                <img :src="'https://' + instanceInfo?.domain + '/images/phone-operator.jpg'" class="card-img-top" alt="Phone operator">
                <div class="card-body">
                    <h4>Fast & Friendly</h4>
                    <p class="card-text">Customer Support</p>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card h-100">
                <div class="card-body d-flex flex-column justify-content-around">
                    <p class="lead">
                        Do you have question, or do you need to validate items on your
                        <strong>Credit Card</strong> bank statement? We will gladly assist you by:
                    </p>

                    <ul>
                        <li>
                            <p>Offering clear and detailed information to identify your digital purchases.</p>
                        </li>
                        <li>
                            <p>Confirm transactions and descriptions on your statement for digital products we do billing
                                for.</p>
                        </li>
                    </ul>

                    <p>For other inquiries, simply contact us and we will get in touch within 24 hours on working days.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-5">
        <div class="col-12 col-md-6 col-lg-4">
            <h3>Contact us via:</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-8">
            <div class="card">
                <div class="card-body">
                    <h5>
                        <i class="fa-solid fa-envelope"></i>
                        Contact Form
                    </h5>

                    <p>Do you have questions regarding a Credit Card payment?</p>
                    <router-link to="/contact" class="btn btn-lg btn-primary">
                        Go To Form
                        <i class="fa-solid fa-chevron-right"></i>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Home',
    inheritAttrs: false,
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
};
</script>
