<template>
    <div class="row my-4 justify-content-center">
        <div class="col-12 col-lg-10">
            <h3>Customer Service</h3>
            <h5>for Credit Card</h5>
            <p class="lead">
                Please, fill out as much information as you can to receive a prompt response.
                <br><br>
                Customer Support <br>
                Email: {{ instanceInfo.supportEmail }} <br>
                Phone: {{ instanceInfo.phoneNumber }} (Only for Credit Card inquiries)
            </p>
        </div>

        <div class="col-12 col-lg-10">
            <div class="card card-body">
                <form class="row" id="request-form" @submit.prevent="submitForm">

                    <h5 class="my-3">Personal Information</h5>

                    <div class="col-12 col-lg-6">
                        <div class="row mb-3">
                            <label for="request-type" class="col-sm-4 col-form-label">Request</label>
                            <div class="col-sm-8">
                                <select v-model="requestCategory" id="request-type" name="request" class="form-select"
                                    aria-label="Request type" required="">
                                    <option value="General Question">General question</option>
                                    <option value="Fraud">Fraud</option>
                                    <option value="Refund Request">Refund request</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row mb-3">
                            <label for="request-username" class="col-sm-4 col-form-label
                                                                    ">Username</label>
                            <div class="col-sm-8">
                                <input v-model="username" type="text" name="username" class="form-control
                                                                            " id="request-username">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row mb-3">
                            <label for="request-email" class="col-sm-4 col-form-label
                                                                    ">Email *</label>
                            <div class="col-sm-8">
                                <input v-model="email" type="email" name="email" class="form-control
                                                                            " id="request-email" required="">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row mb-3">
                            <label for="request-first-name" class="col-sm-4 col-form-label
                                                                    ">First Name *</label>
                            <div class="col-sm-8">
                                <input v-model="firstName" type="text" name="first_name" class="form-control
                                                                            " id="request-first-name" required="">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row mb-3">
                            <label for="request-last-name" class="col-sm-4 col-form-label
                                                                    ">Last Name *</label>
                            <div class="col-sm-8">
                                <input v-model="lastName" type="text" name="last_name" class="form-control
                                                                            " id="request-last-name" required="">
                            </div>
                        </div>
                    </div>

                    <h5 class="my-3">Card Information</h5>

                    <div class="col-12 col-lg-4">
                        <div class="mb-3">
                            <label for="request-card" class="form-label
                                                                        ">Card Number</label>
                            <div class="input-group">
                                <input v-model="cardFirst6" type="text" name="card_first" maxlength="6" class="form-control"
                                    id="request-card" placeholder="First 6 digits">
                                <input v-model="cardLast4" type="text" name="card_last" maxlength="4" class="form-control"
                                    placeholder="Last 4 digits">
                            </div>



                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="mb-3">
                            <label for="request-transaction-date" class="form-label
                                                                     ">Transaction Date</label>
                            <input v-model="transactionDate" type="date" name="transaction_date" class="form-control
                                                                    " id="request-transaction-date">
                        </div>
                    </div>

                    <h5 class="my-3">Additional Information</h5>

                    <div class="col-12">
                        <div class="mb-3">
                            <label for="request-description" class="form-label
                                                                    ">Description *</label>
                            <textarea v-model="message" name="description" id="request-description" cols="30" rows="3"
                                class="form-control
                                                                    " required=""></textarea>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-lg btn-success w-100" id="submitBtn" :disabled="submitSuccessful || submitFailed">
                        Submit <span class="spinner-border spinner-border-sm visually-hidden" id="loader" role="status"
                            aria-hidden="true"></span>
                    </button>

                </form>
            </div>
            <div v-if="submitSuccessful" class="alert alert-success my-3 alert-dismissible fade show">
                <p>Thank you for contacting us, we have received your message!</p>
                <button @click="clearSuccessMessage" type="button" class="btn-close" data-bs-dismiss="alert"
                    aria-label="Close"></button>
            </div>
            <div v-if="submitFailed" class="alert alert-danger my-3 alert-dismissible fade show">
                <p>Your message couldn't be delivered. Please try contacting us by email!</p>
                <button @click="clearFailureMessage" type="button" class="btn-close" data-bs-dismiss="alert"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { registerContactMessage } from '@/app/api/contact';

export default {
    name: 'Contact',
    inheritAttrs: false,
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
    setup() {
        const submitSuccessful = ref(false);
        const submitFailed = ref(false);

        const requestCategory = ref('General Question');
        const username = ref('');
        const email = ref('');
        const firstName = ref('');
        const lastName = ref('');
        const cardFirst6 = ref('');
        const cardLast4 = ref('');
        const transactionDate = ref('');
        const message = ref('');

        const submitForm = () => {
            const contactRequest = {
                requestCategory: requestCategory.value,
                username: username.value,
                email: email.value,
                firstName: firstName.value,
                lastName: lastName.value,
                cardFirst6: cardFirst6.value,
                cardLast4: cardLast4.value,
                transactionDate: transactionDate.value,
                message: message.value,
            };
            registerContactMessage(contactRequest)
                .then(() => {
                    submitFailed.value = false;
                    submitSuccessful.value = true;
                    requestCategory.value = 'General Question';
                    username.value = '';
                    email.value = '';
                    firstName.value = '';
                    lastName.value = '';
                    cardFirst6.value = '';
                    cardLast4.value = '';
                    transactionDate.value = '';
                    message.value = '';
                })
                .catch(() => {
                    submitFailed.value = true;
                });
        };

        const clearSuccessMessage = () => {
            submitSuccessful.value = false;
        };

        const clearFailureMessage = () => {
            submitFailed.value = false;
        };

        return {
            submitSuccessful,
            submitFailed,
            requestCategory,
            username,
            email,
            firstName,
            lastName,
            cardFirst6,
            cardLast4,
            transactionDate,
            message,
            submitForm,
            clearSuccessMessage,
            clearFailureMessage
        };
    },
};
</script>
