<template>
    <div class="row my-4">
        <div class="col-12">
            <h1>PRIVACY POLICY</h1>
            <p>
                Last Modified: June 14, 2022
            </p>

            <hr class="my-4">

            <h2>INTRODUCTION</h2>

            <p>

                If you have been billed by
                this Merchant account, you have agreed to the Privacy Policy of one or several {{ instanceInfo.productDescription }}s.
                The exact Website you have signed up to can be identified on your credit card or bank statement or under
                settings at
                the time of purchase, by contacting our customer service department per email via the links on this
                site.

            </p>
            <p>

                The Privacy Policy you have accepted
                for your purchase is available on the Website you have purchased a membership or digital credit bundle
                from. A
                summary can be found herein. For more information about that Privacy Policy, or to view a more complete
                Privacy
                Policy, please visit the Website you have purchased a Membership or digital credit bundle from to or
                contact our
                customer service department.

            </p>
            <p>

                The present Privacy Policy
                Statement details important information regarding the use and disclosure of user information and/or data
                gathered
                regarding Subscribers ("you") of {{ instanceInfo.productDescription }}s billed by the entity
                appearing on
                your cardholder statement, the Merchant. In addition, this Privacy Policy sets forth relevant policies
                and practices
                while using
                <router-link to="/" target="_blank">
                    https://www.{{ instanceInfo.domain }}/ </router-link>
                <span>("the Support Site").</span>

            </p>

            <h2>DEFINITIONS</h2>

            <p>

                "Merchant" as referred to in this document shall
                mean: The billing identifier or purchase description appearing on your credit card statement which has
                led you to
                the Support Site. Examples include but are not limited to: {{ instanceInfo.name.toLowerCase() }}.

            </p>

            <p>

                "Subscriber" as referred to in this document shall mean:
                The Subscriber or "purchaser" of a Membership or digital credit bundle to a website billed by
                Merchant. The use of
                "you" in this document is synonymous with Subscriber. "Website" as referred to in
                this document shall mean: The
                {{ instanceInfo.productDescription }}, billed by the Merchant, to which a Membership has been purchased by
                Subscriber.

            </p>
            <h2>USE</h2>

            <p>

                The {{ instanceInfo.productDescription }} you have
                purchased a membership from does not knowingly seek or collect any personal information or data from
                persons under
                the age of 18. All persons under the age of 18 are prohibited from
                accessing or viewing the contents on the Website without exception. If you are under the age of 18
                and/or under the
                age of majority in the jurisdiction you reside and from which you access the Website, then your account
                will be
                terminated without notice or delay.

            </p>

            <p>

                Please read this Privacy Policy carefully, as your
                access to and use of the Support Site signifies that you have read, understand and agree to all terms
                within this
                privacy policy. If you do not agree with any part of this privacy policy or our terms, please do not
                access or
                continue to use.

            </p>

            <p>

                The Support Site or
                otherwise submit your personal data. We respect your privacy and are committed to protecting your
                personal data. If
                you have any questions about our privacy practices, please see "Contact Information" below for
                information on how to
                contact us.

            </p>
            <p>

                We collect, process
                and retain personal data to the extent it is necessary to provide users with our service. This privacy
                policy
                applies to information we collect:

            </p>
            <ul id="l1">
                <li>
                    <p>
                        on the Support Site,
                    </p>
                </li>
                <li>
                    <p>
                        in e-mail, text and other communications between you and the Support Site,
                    </p>
                </li>
                <li>
                    <p>

                        through mobile
                        applications you download from the Support Site, which provide dedicated non-browser-based
                        interaction between
                        you and the Support Site, or

                    </p>
                </li>
                <li>
                    <p>
                        when you interact with our
                        advertising and applications on third-party websites and services, if those applications or
                        advertising include
                        links to this Privacy Policy.
                    </p>
                </li>
            </ul>

            <h2>THE DATA WE COLLECT ABOUT YOU</h2>

            <p>
                Personal data, or personal information, means any
                information about an individual from which that person can be identified by ("Personal Information").
                It does not include data that has been anonymized or pseudonymized.
            </p>

            <p>
                We may collect, use, store and
                transfer different kinds of personal data about you, which we have grouped together as follows:
            </p>

            <ul id="l2">
                <li>
                    <p>
                        Identity Data includes first name,
                        maiden name, last name, username or similar identifier, marital status, title, date of birth and
                        gender.
                    </p>
                </li>
                <li>
                    <p>
                        Contact Data includes billing address, email address
                        and telephone numbers.
                    </p>
                </li>
                <li>
                    <p>
                        Financial Data includes
                        bank account and payment card details.
                    </p>
                </li>
                <li>
                    <p>
                        Transaction Data includes
                        details about payments to and from you and other details of products and services you have
                        purchased or received
                        from our partner sites.
                    </p>
                </li>
                <li>
                    <p>
                        Technical Data includes
                        internet protocol (IP) address, your login data, browser type and version, time zone setting and
                        location,
                        browser plug-in types and versions, operating system and platform and other technology on the
                        devices you use to
                        access the Support Site.
                    </p>
                </li>
                <li>
                    <p>
                        Profile Data includes your username and
                        password, purchases or orders made by you, your interests, preferences, feedback and survey
                        responses.
                    </p>
                </li>
                <li>
                    <p>
                        Usage Data includes information about how you
                        use the Support Site, products and services.
                    </p>
                </li>
                <li>
                    <p>
                        Marketing and Communications Data includes your
                        preferences in receiving marketing from us and our third parties and your communication
                        preferences.
                    </p>
                </li>
                <li>
                    <p>
                        We may also collect, use and share your data to
                        produce and share aggregated insights that do not identify you. Aggregated data may be derived
                        from your
                        personal data but is not considered personal data as this data does not directly or indirectly
                        reveal your
                        identity.
                    </p>
                </li>
                <li>
                    <p>
                        We do not collect special
                        categories of Personal Information about you (this includes details about your race or
                        ethnicity, religious
                        or philosophical beliefs, political opinions, trade union membership, information about your
                        health and
                        genetic and biometric data).
                    </p>
                </li>
            </ul>

            <h2>HOW IS YOUR PERSONAL INFORMATION
                COLLECTED?</h2>

            <p>
                Direct interactions. Information that you
                provide when you contact us for support or by filling in forms on the Support Site, in
                particular at the
                time of registering to use the Support Site, posting material, report a problem with the Support
                Site or
                requesting further services.
            </p>
            <h2>INFORMATION COLLECTED THROUGH
                AUTOMATIC DATA COLLECTION TECHNOLOGIES</h2>

            <p>
                See "Choices About How We Collect, Use and
                Disclose Your Personal Information" below for information on how you can opt out of
                behavioral tracking on
                the Support Site and how we respond to web browser signals and other mechanisms that enable
                consumers to
                exercise choice about behavioral tracking.
            </p>

            <p>
                The technologies we use for this automatic data
                collection may include: Cookies (or browser cookies). Cookies are small text files that are
                stored in your
                web browser or downloaded to your device when you visit a website. Cookies are then sent back to
                the
                originating website on each subsequent visit, or to another website that recognizes that cookie,
                and allow a
                website to recognize a user’s device.
            </p>

            <p>
                We currently use the following types of
                cookies:
            </p>

            <p>
                Cookies that are necessary: These are cookies
                that are required for the operation of the Support Site. These include, for example, cookies
                that enable a
                user to log in to the Support Site and to check if a user is allowed access to a particular
                service.
            </p>

            <p>
                Functionality cookies: These cookies are not
                essential but help us to personalize and enhance your online experience on the Support Site.
                This type of
                cookies allows us to recognize you when you return to the Support Site and to remember, for
                example, your
                choice of language.
            </p>

            <h2>USE OF PERSONAL SUBSCRIBER INFORMATION</h2>

            <p>
                We will only use your personal
                data when the applicable local law allows us to. Most commonly, we will use your personal data
                in the
                following circumstances:
            </p>

            <ul>
                <li>
                    <p>
                        For purposes of providing the
                        support, services, customer management and functionality and security as is necessary to perform
                        the
                        services provided to you under our term and conditions and any other contract that you have with
                        us.
                    </p>
                </li>
                <li>
                    <p>
                        Where it is necessary for our legitimate
                        interests (or those of a third party) and your interests and fundamental rights do not override
                        those
                        interests.
                    </p>
                </li>
                <li>
                    <p>
                        Where we need to comply with a legal or regulatory obligation.
                    </p>
                </li>
            </ul>

            <p>
                Note that we
                may process your personal data for more than one lawful ground depending on the specific purpose
                for which
                we are using your data.
            </p>
            <p>
                If a Subscriber sends any
                personal communication or correspondence by any means, to the Support Site, or any of its
                employees, agents
                or representatives, the Support Site may collect the information regarding that communication
                and include
                that information in its customer database. The Support Site may also automatically collect
                traffic and
                click-through data as well as information regarding the online behavior of Subscribers by the
                use of "cookies"
                or other programming means -any information about Subscribers collected by the Support Site
                through the use
                of cookies or other programming means will not be associable to the specific user in any way.
            </p>

            <h2>PURPOSES FOR WHICH WE USE YOUR PERSONAL
                INFORMATION</h2>

            <p>
                In general, we use information that we collect
                about you or that you provide to us, including Personal Information, for following purposes:
            </p>

            <p>
                -Provision of services: to provide you with
                support in case you need to validate items on your Credit Card, offer clear and detailed
                information, which
                will help you identify purchases of our products easily on your according statement (Credit
                Card) in case of
                issues. Confirm transactions in case descriptions on your statements are confusing or do not
                match a product
                you have recently purchased.
            </p>
            <p>
                -Customer management: to manage a registered
                users’ account, to provide customer support and notices to the registered user about his account
                or
                subscription, including expiration and renewal notices, and notices about changes to the Support
                Site or any
                products or services we offer or provide through it;
            </p>
            <p>
                -Advertising: to communicate with you about
                products or services that may be of interest to you either from us, our affiliates or other
                third parties
                (For more information, see "Choices About How We Collect, Use and Disclose Your Personal
                Information");
            </p>
            <p>
                -Functionality and security: to diagnose or fix
                technology problems, and to detect, prevent, and respond to actual or potential fraud, illegal
                activities,
                or intellectual property infringement;
            </p>
            <p>
                -Compliance: to enforce our terms and conditions
                and to comply with our legal obligations; in any other way we may describe when you provide the
                information;
                or for any other purpose with your consent provided separately from this privacy policy.
            </p>

            <h2>DISCLOSURE OF YOUR PERSONAL INFORMATION</h2>

            <p>
                We do not disclose your
                Personal Information except in the limited circumstances described here.
            </p>
            <p>
                -We may disclose your Personal
                Information to members of our corporate group (that is, entities that control, are controlled
                by, or are
                under common control with us) to the extent this is necessary for the purposes of provision of
                services,
                customer management, customization of content, advertising, analytics, verifications,
                functionality and
                security, and compliance.
            </p>
            <p>
                -Service providers. To our
                authorized service providers that perform certain services on our behalf. These services may
                include
                fulfilling orders, processing credit card payments, risk and fraud detection and mitigation,
                providing
                customer service, performing business and sales analysis, customization of content, analytics,
                security,
                supporting the Support Site functionality, and supporting contests, sweepstakes, surveys and
                other features
                offered through the Support Site. These service providers may have access to Personal
                Information needed to
                perform their functions but are not permitted to share or use such information for any other
                purposes.
            </p>

            <p>
                -Legal successors. To a buyer or other successor
                in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale
                or transfer
                of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation
                or similar
                proceeding, in which personal information held by us about the Support Site users is among the
                assets
                transferred. Should such a sale or transfer occur, we will use reasonable efforts to try to
                ensure that the
                entity to which we transfer your personal information uses it in a manner that is consistent
                with this
                privacy policy.
            </p>

            <p>
                We access, preserve and share your Personal
                Information with regulators, law enforcement or others where we reasonably believe such
                disclosure is needed
                to (a)satisfy any applicable law, regulation, legal process, or governmental request, (b)enforce
                applicable
                terms of use, including investigation of potential violations thereof, (c)detect, prevent, or
                otherwise
                address illegal or suspected illegal activities, security or technical issues, (d)protect
                against harm to
                the rights, property or safety of our company, our users, our employees, or others; or (e) to
                maintain and
                protect the security and integrity of the Support Site or infrastructure.
            </p>

            <p>
                In such cases, we
                may raise or waive any legal objection or right available to us, in our sole discretion. We may
                disclose
                aggregated information about our users, and information that does not identify any individual,
                without
                restriction. We also may share aggregated information with third parties for conducting general
                business
                analysis. This information does not contain any Personal Information and may be used to develop
                content and
                services that we hope you and other users will find of interest and to target content and
                advertising.
            </p>
            <h2>FINANCIAL INFORMATION</h2>

            <p>
                Financial information (including Personal
                Information) that you have provided to us will only be shared with our third-party processors in
                order to
                initiate and complete any orders placed on your account. All credit card transactions and such
                are processed
                with industry standard encryption through third party processors who only use your financial
                information and
                Personal Information for that purpose.
            </p>
            <h2>TRANSFERS OF YOUR PERSONAL
                INFORMATION TO OTHER COUNTRIES</h2>

            <p>
                Whenever in the
                course of sharing information we transfer Personal Information to countries outside of the
                European Economic
                Area and other regions with comprehensive data protection laws, we will ensure that the
                information is
                transferred in accordance with this privacy policy and as permitted by the applicable laws on
                data
                protection. By using the
            </p>
            <p>
                Support Site you consent to
                the transfer of information that we collect about you, including personal information, to any
                country in
                which we, members of our corporate group (that is, entities that control, are controlled by, or
                are under
                common control with us) or our service providers are located.
            </p>

            <h2>SECURITY OF INFORMATION COLLECTED BY THE
                SUPPORT SITE</h2>

            <p>
                We take appropriate security measures (including
                physical, electronic and procedural measures) to safeguard your Personal Information from
                unauthorized
                access and disclosure. For example, only authorized employees are permitted to access Personal
                Information,
                and they may do so only for permitted business functions. In addition, we use encryption in the
                transmission
                of your Personal Information between your system and ours, and we use firewalls to help prevent
                unauthorized
                persons from gaining access to your Personal Information. Please be advised, however, that we
                cannot fully
                eliminate security risks associated with the storage and transmission of Personal Data. You are
                responsible
                for maintaining the secrecy of your unique password and account information at all times. We are
                not
                responsible for circumventions of any privacy settings or security measures contained on the
                Support
                Site.
            </p>

            <h2>CHOICES ABOUT HOW WE COLLECT, USE AND DISCLOSE
                YOUR PERSONAL INFORMATION</h2>

            <p>
                We strive to provide you with choices regarding
                the Personal Information you provide to us.
            </p>

            <p>
                -You can choose not to provide us with certain
                Personal Information, but that may result in you being unable to use certain features of the
                Support Site
                because such information may be required in order for you to register as a member; purchase
                products or
                services; participate in a contest, promotion, survey, or sweepstakes; ask a question; or
                initiate other
                transactions the Support Site.
            </p>

            <p>
                -You can set your browser to refuse all or some
                browser cookies, or to alert you when cookies are being sent. To learn how you can manage your
                Flash cookie
                settings, visit the Flash player settings page on Adobe’s website. If you disable or refuse
                cookies, please
                note that some parts of the Support Site may then be inaccessible or not function properly.
            </p>
            <p>
                When you register on the
                Support Site, you may be given a choice as to whether you want to receive email messages,
                newsletters or both
                about product updates, improvements, special offers, or containing special distributions of
                content by us. If
                you no longer want to receive commercial or promotional emails or newsletters from us, you will
                need to avail
                yourself of the unsubscribe mechanism set out in the applicable communication. It may take up to
                seven days for
                us to process an opt-out request. We may send you other types of transactional and relationship
                e-mail
                communications, such as service announcements, administrative notices, and surveys, without
                offering you the
                opportunity to opt out of receiving them. Please note that opting out of receipt of promotional
                email
                communications will only affect future activities or communications from us. If we have already
                provided your
                information to a third party before you have changed your preferences or updated your
                information, you may have
                to change your preferences directly with that third party.
            </p>
            <p>
                -You may opt out of
                interest-based advertising on mobile devices by activating the "Limit Ad Tracking" or
                equivalent feature
                offered by your mobile platform. It will transmit a signal to us and to third-parties that
                indicates your
                data should not be used for interest-based advertising.
            </p>

            <p>
                -If you submit Personal Information, you may
                delete your account with us at any time. If you delete your account, your Personal Information
                and any and
                all other account related information including, but not limited to, user profile data, sharing
                data and any
                other data, or content specifically associated with your account will no longer be accessible by
                you.
            </p>
            <p>
                After deleting your account, if you choose to
                have an account with us in the future, you will have to sign up for a new account as none of the
                information
                you previously provided or saved within your account will have been saved.
            </p>
            <h2>YOUR RIGHTS RELATED TO YOUR
                PERSONAL INFORMATION</h2>

            <p>
                Subject to local law, you have certain rights
                regarding the Personal Information we collect, use or disclose and that is related to you,
                including the
                right
            </p>
            <p>
                to receive information on the
                Personal Information concerning we hold about you and how such Personal Information is used
                (right to
                access);
            </p>
            <p>
                -to rectify inaccurate Personal Information concerning
                you (right to data rectification); to delete/erase your Personal Information (right to
                erasure/deletion,
                "right to
                be forgotten"); -to receive the Personal Information provided by you in a structured, used and
                machine-readable
                format and to transmit those Personal Information to another data controller (right to data
                portability)
                to object
                to the use of your Personal Information where such use is based on our legitimate interests or on
                public
                interests
                (right to object); and
            </p>
            <p>
                -in some cases, to restrict our use of your Personal
                Information (right to restriction of processing).
            </p>

            <p>
                If we ask for your consent to use your Personal
                Information, you can withdraw your consent at any time. Note that in case of a withdrawal of your
                consent you may no
                longer able to use several functionalities of the Support Site and our services.
            </p>

            <p>

                You may, at any time, send us an e-mail at <a :href="'mailto:' + instanceInfo.supportEmail"
                    target="_blank">{{ instanceInfo.supportEmail }}</a>
                to exercise your above rights in accordance with the applicable legal requirements
                and limitations. If you are located in the European Economic Area, you have a right to lodge a
                complaint
                with your
                local data protection authority.

            </p>

            <p>

                Note that some requests to delete certain Personal
                Information will require the deletion of your user account as the provision of user accounts are
                inextricable linked
                to the use of certain Personal Information (e.g., your e-mail address). Also note that it is
                possible
                that we
                require additional information from you in order to verify your authorization to make the request
                and to
                honor your
                request.

            </p>

            <h2>CHANGES TO OUR PRIVACY POLICY</h2>

            <p>

                We may modify or revise our privacy policy from time to
                time. Although we may attempt to notify you when major changes are made to this privacy policy, you
                are
                expected to
                periodically review the most up-to-date version found on the Support Site, so you are aware of any
                changes, as they
                are binding on you.

            </p>

            <p>

                If we change anything in our privacy policy, the date of
                change will be reflected in the "last modified date". You agree that you will periodically
                review this privacy
                policy and refresh the page when doing so. You agree to note the date of the last revision to our
                privacy policy. If
                the "last modified" date is unchanged from the last time you reviewed our privacy policy,
                then
                it is unchanged. On
                the other hand, if the date has changed, then there have been changes, and you agree to re-review
                our
                privacy
                policy, and you agree to the new ones. By continuing to use the Support Site subsequent to us making
                available an
                amended version of our privacy policy in a way that you can easily take notice of it, you thereby
                consent to such
                amendment.

            </p>

            <h2>TERMS AND CONDITIONS OF MEMBERSHIP</h2>

            <p>

                Each paid member should carefully read each of the terms
                and conditions of membership for the Support Site. By accepting membership to the Website, paid
                member
                is
                unconditionally accepting all of those terms and conditions. Some of those terms and conditions may
                also
                affect the
                right of the Support Site to use information that it has gathered from paid member.

            </p>

            <h2>ENFORCEMENT; COOPERATION</h2>

            <p>

                We regularly review our compliance with this privacy
                policy. Please feel free to direct any questions or concerns regarding this privacy policy or our
                treatment of
                Personal Information by contacting us through the Support Site.

            </p>

            <p>
                When we receive a formal written complaint, it is our
                policy to contact the complaining party regarding his or her concerns. We will cooperate with the
                appropriate
                regulatory authorities, including local data protection authorities, to resolve any complaints
                regarding
                the
                collection, use and disclosure of Personal Information that cannot be resolved by an individual and
                us.
            </p>

            <p>
                NO RIGHTS OF THIRD PARTIES
            </p>
            <p>
                This privacy policy does not create rights enforceable
                by third parties or require disclosure of any Personal Information relating to users of the Support
                Site.
            </p>
            <h2>OUR POLICY TOWARDS MINORS</h2>

            <p>

                The Support Site is not directed to
                persons under the age of 18 or the applicable age of majority in the jurisdiction from which the
                Support
                Site is
                accessed and we do not knowingly collect Personal Information from minors. If you become aware that
                your
                child has
                provided us with Personal Information, please contact us <a :href="'mailto:' + instanceInfo.supportEmail"
                    target="_blank">{{ instanceInfo.supportEmail }}.</a>
                If we become aware that a minor
                has provided us with Personal Information, we take steps to remove such information and terminate
                that
                person’s
                account.

            </p>

            <h2>NO ERROR FREE PERFORMANCE</h2>

            <p>
                We do not guarantee error-free performance under this
                privacy policy. We will use reasonable efforts to comply with this privacy policy and will take
                prompt
                corrective
                action when we learn of any failure to comply with our privacy policy.
            </p>

            <p>

                We shall not be liable for any
                incidental, consequential or punitive damages relating to this privacy policy.

            </p>

            <p>

                <strong>Manage Your Private Data</strong> <br>
                1). Request a Copy of my Data <br>
                Go to your account Settings and select: "Request my Data"

            </p>
            <p>

                2). Request Deletion of my Data <br>
                Go to your account Settings and select: "Delete my account"

            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Privacy',
    inheritAttrs: false,
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
};
</script>
