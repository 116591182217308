<template>
<div class="row my-4">
        <h1>Terms &amp; Conditions</h1>
        <p>
            Last Modified: June 14, 2022
        </p>

        <hr class="my-4">

        <h2>General</h2>

        <p>
            When you visit and use this website the general
            terms
            and conditions apply.
        </p>

        <p class="s1">
            Read the terms and
            conditions carefully before using our services. If you do not agree with one or more points of our Terms and
            Conditions, discontinue your visit please at this point.
        </p>
        <p>
            This or
            future visits
            to this website and / or using our services implies that you accept these terms and conditions in full and
            without
            reservation.
        </p>

        <h2>Article 1: Definitions</h2>

        <h4>1.1</h4>

        <p>
            Owner: {{ instanceInfo.companyName }},
            {{ instanceInfo.companyAddress1 }}, {{ instanceInfo.companyAddress2 }}, is the owner of the website
            <a class="a" :href="'https://' + instanceInfo.domain" target="_blank">https://{{ instanceInfo.domain }}</a>.
            Hereinafter referred to as "owner" means.
        </p>

        <h4>1.2</h4>

        <p>
            Hyperlink: the primary purpose of links is to
            present
            information and to verify human users.
        </p>

        <h4>1.3</h4>

        <p>Website: A medium for presenting information on
            the
            Internet.</p>

        <h4>1.4</h4>

        <p>
            User: the person visiting this Website and / or
            uses the
            services provided by Owner.
        </p>

        <h4>1.5</h4>

        <p>
            SSL certificate: <span class="p">this means that when the customer enters data into this website with an SSL certificate, the information is encrypted by your browser, sent encrypted to the server hosting the website, and decrypted by the website. The cryptographic key (or cipher key, also known as a private key) is only known by the server the website is hosted on. This means that even if a third party was to obtain the information you sent to the website (first name, last name, credit card number and CCV!), with the most powerful hardware available today, it is theoretically impossible to decrypt without the cipher:</span>
        </p>
        <h2>Article 2:
            Scope and
            nature of the Terms and Conditions</h2>
        <h4>2.1</h4>
        <p>These terms and conditions
            apply
            between Owner and the user.</p>

        <h4>2.2</h4>

        <p>These terms and conditions are applicable to the
            use of
            this website.</p>

        <h4>2.3</h4>

        <p>Order of validity: the general
            terms
            and conditions. In addition, the law of the European legislation, so far this has not been processed in the
            local
            law.</p>
        <h4>2.4</h4>

        <p>If any part of these
            General Terms
            and Conditions is invalid or void, this shall not affect the validity of the remaining provisions. The areas
            in
            question are converted into a coverage that is closest to the original purpose.</p>
        <h2>Article 3:
            Content
            and use of the website</h2>
        <h4>3.1</h4>
        <p>The goal of this site is to
            support
            users that paid via Credit Card on one of our partner sites where we offer billing solutions for.</p>
        <h4>3.2</h4>

        <p>Use of this website and the services offered
            through
            this website are available for any person who is at least 18 years old. If you have not yet reached the age
            of 18
            years, have objections to the content of this site and / or users of this site or the services offered or
            are
            located in a geographical area in which has forbidden access to the website or the use of services provided,
            we
            advise that you stop use of this website immediately.</p>
        <h2>Article 4: Liability</h2>

        <h4>4.1</h4>

        <p>Owner makes every effort to
            make the
            website available at all the times and maintained through all available means.</p>
        <h4>4.2</h4>

        <p>The user may Owner in no way
            liable
            for losses due to maintenance, problems, interventions, changes or restrictions to the functionality or
            other things
            that have an impact on the availability of the Site or other services of Owner.</p>
        <h2>Article 5:
            Privacy,
            personal description and examination of the use</h2>
        <h4>5.1</h4>
        <p>Owner can exercise the utmost
            care
            when it comes to the protection of personal data of users of this website.</p>
        <h4>5.2</h4>

        <p>During the visit to the site and / or use of the
            Services, information of the user will be logged. This information is necessary for the proper functioning
            of the
            website, the optimization / improvement of the website and other services of Owner. This information can be
            used for
            troubleshooting and / or monitoring of potential exceedances of the terms and conditions of the statutory
            provisions
            or for other things that Owner deems necessary.</p>
        <h4>5.3</h4>

        <p>Owner can exercise the utmost
            care
            when it comes to the protection of such information and will never share this information with third
            parties.</p>
        <h4>5.4</h4>

        <p>In exceedances or violations of the provisions
            in the
            Terms and Conditions, actions that violate the law and / or fraud, the account is blocked immediately and
            without
            warning and terminates the membership immediately. It will cause the forfeiture of acquired balances
            directly and
            irrevocably. The costs associated with this determination of abuse or fraud are placed on the user loads.
            This gives
            Owner the right to transfer the damage suffered and resulting judicial and extrajudicial costs to the
            user.</p>
        <h2>Article 6: Payments</h2>

        <h4>6.1</h4>

        <p>The contact Owner are decisive
            for the
            establishment of mandatory for payment amounts, unless the customer can demonstrate that these contact
            details are
            incorrect proven.</p>
        <h4>6.2</h4>

        <p>All fares are valid and binding for payment from
            the
            moment of entering into the agreement.</p>

        <h4>6.3</h4>

        <p>Note: The digital services purchased are valid
            for a
            maximum of 12 months.</p>

        <h2>Article 7: Revocation</h2>

        <h4>7.1</h4>

        <p>To learn more about revocation please click <a :href="'https://' + instanceInfo.domain + '/docs/Revocation_CreditCard.pdf'" target="_blank">here</a>.</p>

        <h2>Article 8: Complaint handling</h2>
        <h4>8.1</h4>
        <p>All complaints in connection
            with the
            use of this website, quality or any other complaints will be treated seriously by Owner.</p>
        <h4>8.2</h4>

        <p>The user is encouraged to make a complaint in
            writing by
            e-mail with Owner identified.</p>

        <h4>8.3</h4>

        <p>Owner is required within 10
            days
            trying to resolve the complaint. Owner will inform the users thereof in writing via e-mail.</p>
        <h2>Article 9:
            User
            conflicts</h2>
        <h4>9.1</h4>
        <p>Since the site is not involved in the
            communication
            between users, in the case of disagreements with other users, you will clear the website and Owner from all
            claims,
            demands and damages (direct or indirect), in any form or mode known or unknown, suspected or unsuspected,
            disclosed
            or undisclosed resulting from or connected with these types of cases.</p>
        <h2>Article 10:
            Intellectual Property</h2>
        <h4>10.1</h4>
        <p>No part of this site may be
            reproduced
            without written consent of Owner. The intellectual property rights in all text, images, sounds and software
            on this
            site belong Owner or its licensors.</p>
        <h2>Article 11:
            The
            prices and terms and conditions changes</h2>
        <h4>11.1</h4>
        <p>Owner waives every form of
            liability
            for any adverse consequences that may result from the changes made to the content of the Website or the
            Terms and
            Conditions from our partner sites.</p>
    </div>
</template>

<script>
export default {
    name: 'Terms',
    inheritAttrs: false,
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
};
</script>
