<template>
    <div class="row my-4">
        <div class="col-12">
            <h3 class="my-4">Imprint</h3>
            <div class="card">
                <div class="card-body">
                    <p class="m-0">
                        <strong>{{ instanceInfo.name }}</strong>, a {{ instanceInfo.companyName }} trade-name. <br>
                        {{ instanceInfo.companyAddress1 }}, {{ instanceInfo.companyAddress2 }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Imprint',
    inheritAttrs: false,
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
};
</script>
